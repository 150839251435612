import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Hang Squat Cleans 4×6\\@70% 1RM`}</p>
    <p>{`Lateral Band Walks 4×6/side`}</p>
    <p>{`then,`}</p>
    <p>{`100/75 Calorie Assault Bike for time.`}{`*`}</p>
    <p>{`*`}{`Every minute on the minute 5 Power Cleans. (135/95)(Rx+ 155/105)`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`This Saturday we’ll be having a free class at 9:00 & 10:15am!  To
sign up please email us at: Daniel\\@crossfittheville.org`}</strong></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      